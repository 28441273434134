import React from 'react';
import './main.scss'
import instagram from '../Imagens/instagram.svg'
import SlideImage from '../components/slideImage'

function Main() {

  return(
    <div className="cardGeneral">
      <section className="cardCentral" >
        <section className="leftSection" >
          <h1 >S | T | R | A | D | O | S</h1>
          <section className="contactSection">
            <a href="https://instagram.com/strados_" target="blank">
              <img src={ instagram } alt="instagram logo"/>
            </a>
            <p>Relógio de pulso em madeira feito sob medida para o seu bom gosto</p>
          </section>
        </section>
        <section className="rigthSection" >
          <SlideImage />
        </section>
      </section>
    </div>
  );
}

export default Main;
