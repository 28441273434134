import React, { useState, useEffect } from 'react';
import relogioFrente3 from '../Imagens/strds2-NewPhoto-2.png';
import relogioTraseita from '../Imagens/strds2-T.png';
import relogioFrente5 from '../Imagens/strds3.png';
import Imagetst from '../components/imagetst'

let index = 0;
function SlideImage() {
const [ imagesrc , setImagesrc ] = useState(relogioFrente5)
const [seconds, setSeconds] = useState(0);

function changeImgSrc () {
  const array = [ relogioFrente5, relogioFrente3, relogioTraseita ];
  document.querySelector('.rigthSection img').style.display = 'none'
  setTimeout(() =>{
    document.querySelector('.rigthSection img').style.display = ''
  },100)
  setImagesrc(array[index]);
  if(index === array.length -1 ) index = -1;
  index = index + 1;
  console.log(array.length, index)
}
useEffect(() => {
  const interval = setInterval(() => {
    setSeconds(seconds => seconds + 1);
  }, 5000);
  return () => clearInterval(interval);
}, []);

useEffect(() => {
  changeImgSrc();
},[seconds])

  return(
    <Imagetst image={imagesrc} />
  );
}

export default SlideImage;
